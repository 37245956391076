import {
  CollectionType,
  IAddress,
  IAditionalCertification,
  IArtist,
  IArtwork,
  IOrder,
  TradePriceInfo,
} from '@/types'
import type { AddressElementProps } from '@stripe/react-stripe-js'
import { OrderPaymentStatus, OrderPayoutStatus } from 'api/order'
import { UserProfile } from 'api/profiles'
import { ArtistCard } from 'components/artists/ArtistCard'
import { ArtistSummary } from 'components/artists/ArtistSummary'
import { ArtworkCard } from 'components/artwork/ArtworkCard'
import { ArtworkDetails } from 'components/artwork/ArtworksDetails'
import { Currency } from 'constants/currencies'
import dayjs from 'dayjs'
import { ComponentProps } from 'react'
import { ArtworkRoyalty, Ask as _Ask, Bid as _Bid } from 'types'
import { getArtworkNameWithEdition } from 'utils/strings'
import { formatLocalDate } from './dates'
import {
  ARTWORK_IMG_PLACEHOLDER,
  ImageExtendedFormat,
  Media,
  getArtistImageUrl,
  getImageUrl,
} from './image'
import { sortArtworkImages } from './sort'
import { getArtistUrl, getArtworkUrl } from './urls'
import { pickTradePrice } from './currencies'

export const mapIArtistToArtistSummary = (artist: IArtist) => {
  const mappedArtist = mapIArtistToArtist(artist)

  if (!mappedArtist) return

  return {
    ...mappedArtist,
    birthYear: mappedArtist?.birthYear?.toString(),
  } as ComponentProps<typeof ArtistSummary>
}

type Nationality = {
  name?: string
  artists?: { data?: IArtist[] }
  numCode?: string
  alpha2Code?: string
  alpha3Code?: string
  shortName?: string
}

export type Artist = {
  id: number
  name: string
  nationalities: Nationality[]
  nationality?: Nationality
  avatar?: Media
  profile?: Media
  birthYear?: number
  href: string
  description?: string
  numberOfArtworks: number
  socialLinks: {
    instagram?: string
    website?: string
    email?: string
  }
  dateOfBirth?: string
}

export type FollowArtist = {
  id: number
  name: string
  nationalities: Nationality[]
  images: Media[]
  dateOfBirth: string
}

export const mapIArtistToArtist = (artist: IArtist) => {
  try {
    const { id, attributes } = artist || {}
    const { name, dateOfBirth, images, nationalities, socialMedia, artworks } =
      attributes || {}

    const avatar = images?.data?.find(
      image =>
        image.attributes.name?.includes('(1)') ||
        image.attributes.name?.includes('1')
    )

    const profile = images?.data?.find(
      image =>
        image.attributes.name?.includes('(2)') ||
        image.attributes.name?.includes('2')
    )

    const description = attributes.description || null
    const { instagram, website, contact: email } = socialMedia || {}
    const socialLinks = {
      instagram: instagram || null,
      website: website || null,
      email: email || null,
    }

    if (!name) return

    return {
      id,
      name,
      avatar: avatar ? { id: avatar.id, ...avatar.attributes } : {},
      profile: profile ? { id: profile.id, ...profile.attributes } : {},
      birthYear: dateOfBirth
        ? parseInt(dayjs(dateOfBirth).format('YYYY'))
        : null,
      birthDate: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null,
      nationalities:
        nationalities?.data?.map(({ attributes }) => ({
          name: attributes.name,
          countryCode: attributes.alpha2Code,
        })) || [],
      href: getArtistUrl(name, id),
      description,
      socialLinks,
      dateOfBirth: dateOfBirth || null,
      numberOfArtworks: artworks?.data?.length ?? 0,
    } as Artist
  } catch (err) {
    console.error(err)

    return undefined
  }
}

export const mapIArtistsToArtists = (artists?: IArtist[]) => {
  if (!artists) return []
  return artists.flatMap(artist => mapIArtistToArtist(artist) || [])
}

export const mapIArtistToArtistCard = (
  artist: IArtist,
  imageFormat: ImageExtendedFormat = 'small'
) => {
  const mappedArtist = mapIArtistToArtist(artist)
  if (!mappedArtist) return
  const { name, avatar, nationality, birthYear, href } = mappedArtist

  return {
    src: getArtistImageUrl(name, avatar, imageFormat),
    name,
    nationality: nationality?.alpha2Code || null,
    birthDate: birthYear?.toString() || null,
    href,
    additionalSrc: {
      medium: getArtistImageUrl(name, avatar, 'medium'),
    },
  } as ComponentProps<typeof ArtistCard>
}

export const mapIArtistsToArtistCards = (
  artists?: IArtist[],
  imageFormat: ImageExtendedFormat = 'small'
) => {
  if (!artists) return []
  return artists.flatMap(
    artist => mapIArtistToArtistCard(artist, imageFormat) || []
  )
}

export type Artwork = {
  id: number
  name: string
  // imageUrl: string | null
  artists: Artist[]
  editions: Edition[]
  images: Media[]
  href?: string
  royalty?: ArtworkRoyalty
}

export function mapTradeAttributesToTradePrices(
  attributes: TradePriceInfo | undefined
): TradePriceInfo | undefined | null {
  if (!attributes?.price) return null

  return {
    currency: attributes?.currency || null,
    price: attributes?.price || null,
    priceConvertedToGbp: attributes?.priceConvertedToGbp || null,
    priceConvertedToUsd: attributes?.priceConvertedToUsd || null,
    priceConvertedToEur: attributes?.priceConvertedToEur || null,
    priceGbp: attributes?.priceGbp || null,
  }
}

export function mapTradeToTradePrices(
  trade: _Ask | _Bid | undefined
): TradePriceInfo | undefined | null {
  return mapTradeAttributesToTradePrices(trade)
}

export const mapIArtworkToArtworkCard = (
  artwork: IArtwork,
  imageFormat: ImageExtendedFormat = 'thumbnail'
): ComponentProps<typeof ArtworkCard> | undefined => {
  const { id, attributes } = artwork || {}
  const { name, images, artists: initialArtists, editions } = attributes || {}

  const image =
    images?.data?.length &&
    images?.data?.sort((img1, img2) => sortArtworkImages(img1, img2))?.[0]

  const artists =
    initialArtists?.data?.map(artist => ({
      id: artist.id,
      name: artist.attributes.name || '',
    })) || null
  const artistName = artists?.[0]?.name || ''

  const mainEdition =
    editions?.data?.find(edition => edition.attributes?.name === 'Main') || null

  const alternativeEditionLo =
    editions?.data?.find(
      edition =>
        edition.attributes.name !== 'Main' &&
        edition.attributes.lowestAsk?.data?.attributes?.price
    ) || null

  const alternativeEditionHb =
    editions?.data?.find(
      edition =>
        edition.attributes.name !== 'Main' &&
        edition.attributes.highestBid?.data?.attributes?.price
    ) || null

  const edition = (() => {
    const alternativeEdtionLo =
      alternativeEditionLo?.attributes?.lowestAsk?.data?.attributes?.price || 0

    const alternativeEdtionHb =
      alternativeEditionHb?.attributes?.highestBid?.data?.attributes?.price || 0

    const mainEdtionLo =
      mainEdition?.attributes?.lowestAsk?.data?.attributes?.price || 0
    const mainEdtionHb =
      mainEdition?.attributes?.highestBid?.data?.attributes?.price || 0

    if (
      alternativeEditionLo &&
      mainEdition &&
      ((alternativeEditionLo?.attributes.lowestAsk?.data &&
        !mainEdition?.attributes.lowestAsk?.data) ||
        alternativeEdtionLo < mainEdtionLo)
    )
      return alternativeEditionLo

    if (
      alternativeEditionHb &&
      mainEdition &&
      !mainEdition?.attributes?.lowestAsk &&
      ((alternativeEditionHb?.attributes.highestBid?.data &&
        !mainEdition?.attributes.highestBid?.data) ||
        alternativeEdtionHb > mainEdtionHb)
    )
      return alternativeEditionHb

    return mainEdition
  })()

  if (!name || !artists || !edition) {
    return
  }

  const getSrc = (format: ImageExtendedFormat) => {
    if (!image) return ARTWORK_IMG_PLACEHOLDER
    return getImageUrl(
      { id: image.id, ...image.attributes },
      format,
      ARTWORK_IMG_PLACEHOLDER
    )
  }

  return {
    id,
    src: getSrc(imageFormat),
    artworkName: getArtworkNameWithEdition(name, edition.attributes?.name),
    artists,
    artistName,
    href: getArtworkUrl(artists, name, id, edition.attributes?.name),
    medium: artwork.attributes?.medium || null,
    releaseDate: artwork.attributes?.releaseDate || null,
    editionName: edition.attributes?.name || null,
    additionalSrc: {
      medium: getSrc('medium'),
    },
    lowestAsk: mapTradeAttributesToTradePrices(
      edition.attributes.lowestAsk?.data?.attributes
    ),
    highestBid: mapTradeAttributesToTradePrices(
      edition.attributes.highestBid?.data?.attributes
    ),
  }
}

export const mapIArtworkToArtwork = (artwork: IArtwork) => {
  const { id, attributes } = artwork || {}
  const { name, images, artists: initialArtists } = attributes || {}

  const editions = mapIArtworkToEditions(artwork)
  const artists = mapIArtistsToArtists(initialArtists?.data)

  if (!name || !artists) return

  return {
    id,
    name,
    artists,
    editions,
    images: images?.data?.map(img => img?.attributes) ?? [],
    href: getArtworkUrl(artists, name, id),
    royalty: attributes.royalty,
  } as Artwork
}

export const mapIArtworksToArtworks = (artworks: IArtwork[]) => {
  if (!artworks) return []
  return artworks.flatMap(artwork => mapIArtworkToArtwork(artwork) || [])
}

export type ArtworkByName = {
  artists: { name: string; id: number }[]
  artworkName: string
  files: { url: string }[]
  id: number
}

export const mapArtworkByQueryToArtworkCard = ({
  artworkName,
  artists,
  files,
  id,
}: ArtworkByName): ComponentProps<typeof ArtworkCard> => {
  return {
    artworkName,
    id,
    href: getArtworkUrl(artists, artworkName, id),
    src: files?.[0]?.url || ARTWORK_IMG_PLACEHOLDER,
    artists,
    artistName: artists?.[0]?.name || ' ',
  }
}

export const mapArtworksByQueryToArtworkCard = (artworks: ArtworkByName[]) => {
  return artworks.map(artwork => mapArtworkByQueryToArtworkCard(artwork))
}

export type Ask = {
  quantity: number
  price: number
  currency: 'USD' | 'GBP' | 'EUR' | null | undefined
}

export type Bid = {
  quantity: number
  price: number
  currency: 'USD' | 'GBP' | 'EUR' | null | undefined
}

export type ShipmentCategory = 'S' | 'M' | 'L' | 'XL' | 'XXL'

export type Edition = {
  id: number
  name: string
  createdAt: string
  otherName: string
  shipmentCategory: ShipmentCategory
  shipmentWeightOverride: number
  artworkDetails: ComponentProps<typeof ArtworkDetails>
  additionalCertification: Omit<IAditionalCertification, 'id'>
  asks: Ask[]
  bids: Bid[]
  // lowestAsk: number
  // lowestAskCurrency?: Currency
  // lowestAskGbp?: number
  // highestBid: number
  // highestBidCurrency?: Currency
  // highestBidGbp?: number
  lowestAsk?: TradePriceInfo | null
  lowestAskId: number
  highestBid?: TradePriceInfo | null
  highestBidId: number
}

export const mapIArtworkToEditions = (artwork: IArtwork) => {
  const { attributes, id } = artwork || {}
  const {
    editions: artworkEditions,
    gallery: artworkGallery,
    medium: artworkMedium,
    releaseDate,
    isFullDate,
    royalty,
    upcomingRelease,
  } = attributes || {}

  const editions =
    artworkEditions?.data?.map(edition => {
      const date = releaseDate
        ? isFullDate
          ? formatLocalDate(dayjs(releaseDate))
          : parseInt(dayjs(releaseDate).format('YYYY'))
        : null
      const size = edition.attributes.size || null
      const medium = artworkMedium || null
      const material = edition.attributes.material || null
      const editionSize = edition.attributes.editionSize
        ? edition.attributes.editionSize
        : null
      const price = edition.attributes.mainPrice || null
      const latestSale = 0 // TODO: Update this once the backend supply it
      const currency = edition.attributes.currency || null
      const gallery = artworkGallery?.data?.attributes.name || null
      const features = edition.attributes.features || null
      const information = edition.attributes.information || null
      const includes = edition.attributes.includes || null
      const shipmentWeightOverride =
        edition.attributes.shipmentWeightOverride || null
      const shipmentCategory = edition.attributes.shipmentCategory || null
      const additionalCertification =
        edition.attributes.additionalCertification || {}

      const pricesToPriceQuantities = (
        items: {
          price: number
          priceGbp: number
          priceConvertedToGbp: number
          priceConvertedToUsd: number
          priceConvertedToEur: number
          currency: 'USD' | 'GBP' | 'EUR' | null | undefined
        }[]
      ) => {
        const unique = [
          ...new Set(
            items.map(item =>
              JSON.stringify({
                quantity: 0,
                price: item.price,
                priceGbp: item.priceGbp,
                priceConvertedToGbp: item.priceConvertedToGbp,
                priceConvertedToUsd: item.priceConvertedToUsd,
                priceConvertedToEur: item.priceConvertedToEur,
                currency: item.currency,
              })
            )
          ),
        ].map(item => JSON.parse(item))

        items.forEach(item => {
          unique.forEach(ask => {
            if (ask.priceGbp === item.priceGbp) {
              ask.quantity += 1
            }
          })
        })

        return unique as {
          quantity: number
          price: number
          priceGbp: number
          priceConvertedToGbp: number
          priceConvertedToUsd: number
          priceConvertedToEur: number
          currency: 'USD' | 'GBP' | 'EUR' | null | undefined
        }[]
      }

      const approvedAsks =
        edition.attributes.asks?.data
          ?.filter(ask => ask.attributes.status === 'Approved')
          ?.sort((ask1, ask2) => {
            if (!ask1.attributes.priceGbp) return -1
            if (!ask2.attributes.priceGbp) return 1

            return ask1.attributes.priceGbp - ask2.attributes.priceGbp
          }) || []
      const asksPrices =
        approvedAsks.map(ask => ({
          price: ask.attributes.price || 0,
          priceGbp: ask.attributes.priceGbp || 0,
          priceConvertedToGbp: ask.attributes.priceConvertedToGbp || 0,
          priceConvertedToUsd: ask.attributes.priceConvertedToUsd || 0,
          priceConvertedToEur: ask.attributes.priceConvertedToEur || 0,
          currency: ask.attributes.currency,
        })) || []

      const approvedBids =
        edition.attributes.bids?.data
          ?.filter(bid => bid.attributes.status === 'Approved')
          ?.sort((bid1, bid2) => {
            if (!bid1.attributes.priceGbp) return 1
            if (!bid2.attributes.priceGbp) return -1

            return bid2.attributes.priceGbp - bid1.attributes.priceGbp
          }) || []
      const bidsPrices =
        approvedBids.map(bid => ({
          price: bid.attributes.price || 0,
          priceGbp: bid.attributes.priceGbp || 0,
          priceConvertedToGbp: bid.attributes.priceConvertedToGbp || 0,
          priceConvertedToUsd: bid.attributes.priceConvertedToUsd || 0,
          priceConvertedToEur: bid.attributes.priceConvertedToEur || 0,
          currency: bid.attributes.currency,
        })) || []

      const asks: Ask[] = pricesToPriceQuantities(asksPrices)
      const bids: Bid[] = pricesToPriceQuantities(bidsPrices)

      const lowestAskId = edition.attributes.lowestAsk?.data?.id || null
      const highestBidId = edition.attributes.highestBid?.data?.id || null

      return {
        id: edition.id,
        name: edition.attributes.name || '',
        createdAt: edition.attributes.createdAt || '',
        otherName: edition.attributes.otherName || '',
        artworkDetails: {
          id,
          isFullDate,
          date,
          size,
          medium,
          material,
          editionSize,
          price,
          latestSale,
          currency,
          gallery,
          features,
          includes,
          information,
          royalty,
          upcomingRelease,
        },
        shipmentCategory,
        shipmentWeightOverride,
        additionalCertification,
        asks,
        bids,
        lowestAsk: mapTradeAttributesToTradePrices(
          edition.attributes.lowestAsk?.data?.attributes
        ),
        lowestAskId,
        highestBid: mapTradeAttributesToTradePrices(
          edition.attributes.highestBid?.data?.attributes
        ),
        highestBidId,
      } as Edition
    }) || []

  return editions
}

export const mapIArtworksToArtworkCards = (
  artworks: IArtwork[],
  imageFormat: ImageExtendedFormat = 'thumbnail'
) => {
  if (!artworks) return []
  return artworks.flatMap(
    artwork => mapIArtworkToArtworkCard(artwork, imageFormat) || []
  )
}

export type Fees = {
  applicationFee: number
  dhlInsuranceFee: number
  processFee: number
  royalitiesFee: number
}

export type Order = {
  id: number
  createdAt: string
  payoutStatus: OrderPayoutStatus
  paymentStatus: OrderPaymentStatus
  orderPaidAt: string
  price: number
  payoutDoneAt?: string
  payoutDate?: string
  seller?: { id: number }
  buyer?: { id: number }
  paymentMethod?: {
    last4: string
    brand: string
    expDate: string
    bankTransfer?: boolean
  }
  exchangeRateName?: string
  exchangeRateValue?: number
  priceGbp?: number
  stripeFeeAmountGbp?: number
  shippingPrice?: number
  shippingPriceGbp?: number
  priceAsk?: number
  currencyAsk?: Currency
  priceBid?: number
  currencyBid?: Currency
} & Fees

export const mapIOrderToOrder = (order: IOrder) => {
  const { id, attributes } = order || {}
  const {
    createdAt,
    paymentStatus,
    price,
    applicationFee,
    dhlInsuranceFee,
    processFee,
    royalitiesFee,
    orderPaidAt,
    payoutDoneAt,
    payoutStatus,
    payoutDate,
  } = attributes || {}
  return {
    id,
    createdAt,
    price,
    paymentStatus,
    applicationFee,
    dhlInsuranceFee,
    processFee,
    royalitiesFee,
    orderPaidAt,
    payoutStatus,
    payoutDoneAt,
    payoutDate,
  } as Order
}

export const flatCollectionType = <T extends CollectionType>(
  collectionType: T
) => {
  return {
    id: collectionType.id,
    ...collectionType.attributes,
  } as { id: number } & T['attributes']
}

type StripeValues = NonNullable<
  NonNullable<AddressElementProps['options']>['defaultValues']
>
export const getStripeValuesFromUserAddress = (
  address?: IAddress,
  phone?: string
): {
  address: NonNullable<StripeValues['address']>
  name: NonNullable<StripeValues['name']>
  phone: NonNullable<StripeValues['phone']>
} => {
  return {
    address: {
      country:
        address?.country && address.country.length === 2
          ? address.country
          : 'GB',
      city: address?.city || '',
      line1: address?.address || '',
      line2: address?.address_2 || '',
      postal_code: address?.postalCode || '',
      state: address?.state || '',
    },
    name: address?.name || '',
    phone: phone || '',
  }
}

export const getUserAddressFromStripeValues = (
  address?: StripeValues['address'],
  name?: StripeValues['name']
): Partial<IAddress> => {
  if (!address)
    return {
      address: '',
      address_2: '',
      country: '',
      city: '',
      name: '',
      postalCode: '',
      state: '',
    }

  return {
    address: address?.line1 || '',
    address_2: address?.line2 || '',
    city: address?.city || '',
    country: address?.country || '',
    name: name || '',
    postalCode: address?.postal_code,
    state: address?.state,
  }
}

export function getUserTimezone(userProfile?: UserProfile) {
  return userProfile?.releaseSettings?.timeZone || dayjs.tz.guess()
}

/**
 * Prioritizes the GBP value of the lowest ask. If it doesn't exist, fallbacks to the `lowestAsk` (which can be either GBP, USD, etc., we don't know here). If neither exists, fallbacks to 0.
 *
 * Using the GBP value, we can make the conversions easier to the user currency and display relevant and valid data.
 */
export function getEditionGBPLowestAsk(edition: Edition) {
  return edition?.lowestAsk?.priceGbp || edition?.lowestAsk?.price || 0
}

/**
 * Prioritizes the GBP value of the highest bid. If it doesn't exist, fallbacks to the `highestBid` (which can be either GBP, USD, etc., we don't know here). If neither exists, fallbacks to 0.
 *
 * Using the GBP value, we can make the conversions easier to the user currency and display relevant and valid data.
 */
export function getEditionGBPHighestBid(edition: Edition) {
  return edition?.highestBid?.priceGbp || edition?.highestBid?.price || 0
}

export function getEditionHighestBidConverted(
  userCurrency: 'USER' | 'GBP' | 'USD' | 'EUR',
  edition: Edition
) {
  const price = pickTradePrice(userCurrency, edition?.highestBid)

  return price || 0
}

export function getEditionLowestAskConverted(
  userCurrency: 'USER' | 'GBP' | 'USD' | 'EUR',
  edition: Edition
) {
  const price = pickTradePrice(userCurrency, edition?.lowestAsk)
  return price || 0
}

export function getEditionGbpValues(edition: Edition) {
  return {
    lowestAsk: getEditionGBPLowestAsk(edition),
    highestBid: getEditionGBPHighestBid(edition),
  }
}
