import { IBid } from '@/types'
import { UserAsk } from 'api/asks'
import { UserOrder } from 'api/order'
import { AskStatus, DocumentStatus } from 'types'
import { API_BASEPATH, Err } from '.'

export const bidErrors = {
  askSold: 'This ask has already been sold',
}

type CreateInitialBidResponse = {
  bidId?: string
  error?: Err
}

type BuyNowResponse = {
  orderId?: number
  bidId?: number
  error?: Err
}

type PlaceBidResponse = {
  bidStatus?: string
  error?: Err
}

export type BidStatus = 'Buying' | 'Approved' | 'Expired' | 'Done' | 'Voided'

export type UserBid = {
  status: BidStatus
  ask?: {
    id: number
    status: AskStatus
    documentStatus: DocumentStatus
  }
  order?: UserOrder
} & Omit<UserAsk, 'status' | 'orders'>

type GetUserBidsRespons = {
  data?: UserBid[]
  maxPage?: number
  error?: Err
}

type GetBidResponse = {
  data?: IBid
  error?: Err
}

type GetBidsResponse = {
  data?: IBid[]
  error?: Err
}

type EditBidWithCheckoutSessionResponse = {
  bid?: IBid
  redirectUrl?: string
  error?: Err
}

type DeleteBidResponse = {
  error?: Err
  data?: unknown
}

export const createInitialBid = async (
  editionId: number,
  price: number,
  expiresInDays: number
) => {
  const response = await fetch(`${API_BASEPATH}/api/bids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      type: 'bid',
      price,
      editionId,
      expiresInDays,
    }),
  })
  const data = await response.json()
  return data as CreateInitialBidResponse
}

export const placeBid = async (bidId: number) => {
  const response = await fetch(`${API_BASEPATH}/api/bids/${bidId}/place`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as PlaceBidResponse
}

export const buyNow = async (editionId: number, lowestAskId: number) => {
  const response = await fetch(`${API_BASEPATH}/api/bids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      type: 'buy',
      editionId,
      lowestAskId,
    }),
  })
  const data = await response.json()
  return data as BuyNowResponse
}

export const getBid = async (bidId: number, query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/bids/${bidId}?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as GetBidResponse
}

export const getBids = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/bids?${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as GetBidsResponse
}

export const getUserBids = async (query?: string) => {
  const response = await fetch(
    `${API_BASEPATH}/api/bids/user/history?${query}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as GetUserBidsRespons
}

export const getUserBidsStats = async () => {
  const response = await fetch(
    `${API_BASEPATH}/api/bids/user/history?type=stats`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data as {
    activeBidsCount: number
    orderedBidsCount: number
    expiredBidsCount: number
    completedOrders: number
    inProgressOrders: number
    orderedBidsSumInUserCurrency: number
    orderedBidsSumInGbp: number
    orderedBidsSumInUsd: number
    orderedBidsSumInEur: number
    artistsRoyaltiesInUserCurrency: number
    artistsRoyaltiesInGbp: number
    artistsRoyaltiesInUsd: number
    artistsRoyaltiesInEur: number
  }
}

export const deleteBid = async (bidId: number) => {
  const response = await fetch(`${API_BASEPATH}/api/bids/${bidId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await response.json()
  return data as DeleteBidResponse
}

export const editBid = async (
  bidId: number,
  payload: {
    expiresInDays: number
    price?: number
  }
) => {
  const response = await fetch(`${API_BASEPATH}/api/bids/${bidId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()
  return data
}

export const unbookBid = async (bidId: number) => {
  const response = await fetch(
    `${API_BASEPATH}/api/bids/update/unbook/bid/${bidId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  const data = await response.json()
  return data
}

export const extendAllBids = async (expiresInDays: number) => {
  const response = await fetch(`${API_BASEPATH}/api/bids/extend-all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ expiresInDays }),
  })
  return (await response.json()) as { error?: Err }
}
