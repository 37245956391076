
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { GoogleTagManager } from '@next/third-parties/google';
import * as Portal from '@radix-ui/react-portal';
import { registerFetchIntercept } from 'api/fetchIntercept';
import { AuthModalObserver } from 'components/abstract/AuthModalObserver';
import ErrorBoundary from 'components/abstract/ErrorBoundary';
import { ThirdPartyScriptsBody } from 'components/abstract/ThirdPartyScriptsBody';
import { Drawer } from 'components/common/Drawer';
import { Intercom } from 'components/common/Intercom';
import { Overlay } from 'components/common/Overlay';
import { RouteGuard } from 'components/common/RouteGuard';
import { Providers } from 'contexts';
import dayjs from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useIsMounted } from 'hooks/useIsMounted';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Poppins, Quicksand } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, Suspense, lazy, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { waitForElement } from 'utils/interactions';
import '../index.sass';
import '../overlay.sass';
import { LoadingStatesObserver } from 'components/abstract/LoadingStatesObserver';
const ToastCloseButton = lazy(() => import('components/common/Toast').then(m => ({ default: m.ToastCloseButton })));
const ToastContainer = lazy(() => import('react-toastify').then(m => ({ default: m.ToastContainer })));
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsBusinessTime);
dayjs.setBusinessTime({
    sunday: null,
    monday: [{ start: '00:00:00', end: '23:59:59' }],
    tuesday: [{ start: '00:00:00', end: '23:59:59' }],
    wednesday: [{ start: '00:00:00', end: '23:59:59' }],
    thursday: [{ start: '00:00:00', end: '23:59:59' }],
    friday: [{ start: '00:00:00', end: '23:59:59' }],
    saturday: null
});
const poppins = Poppins({
    subsets: ['latin'],
    weight: ['400', '500', '700'],
    preload: true,
    display: 'swap',
    variable: '--poppins'
});
const quicksand = Quicksand({
    subsets: ['latin'],
    weight: ['400', '500', '700'],
    preload: false,
    display: 'swap',
    variable: '--quicksand'
});
type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};
const toastProps = {
    autoClose: 5000,
    limit: 1,
    closeButton: <ToastCloseButton />,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: false,
    className: '!w-max !max-w-[80%] !p-0 !z-[99999]',
    toastClassName: '!flex !space-x-4 !bg-cool-50 !border !m-0 !px-6 !py-4 !border-cool-300 !rounded !text-base !font-body !text-cool-800',
    bodyClassName: '!p-0 !m-0',
    toastStyle: {
        wordBreak: 'break-word'
    }
} as const;
function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const router = useRouter();
    const isMounted = useIsMounted();
    // useEffect(() => {
    //   const polyfillSmoothscroll = async () => {
    //     const smoothscroll = (await import('smoothscroll-polyfill')).default
    //     smoothscroll.polyfill()
    //   }
    //   polyfillSmoothscroll()
    // }, [])
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        router.prefetch = async () => { };
    }, [router]);
    /**
     * Manipulates the Termly cookie consent prompt.
     *
     * This is needed because, by default, the cookie consent prompt is detected as LCP on most pages, which affects the metrics. The cause of this is the large text block which is displayed in the prompt.
     *
     * We avoid this by splitting the large text block into multiple paragraphs, which determines the bots to consider the text inside the prompt as a singular block.
     *
     * The cookie consent prompt is hidden on mobile using css in `index.sass` because the manipulation takes a bit of time, in which the bots see the large text of block and determine that as LCP.
     *
     * The cookie consent prompt is shown to the end user after the manipulation is finished.
     */
    useEffect(() => {
        const timeout = 30 * 1000;
        const showCookieBannerPrompt = () => {
            const cookieConsentPrompt = document.querySelector<HTMLDivElement>('div[class^=termly-styles-root]');
            if (cookieConsentPrompt) {
                cookieConsentPrompt.style.display = 'block';
            }
        };
        const replaceCookieBannerTextBlocks = () => {
            // don't manipulate on mobile
            if (window.innerWidth > 640)
                return;
            // wait for the links to be mounted
            // if we try to select the cookie banner text container directly, it might be empty
            // this way, we make sure that the text was loaded
            waitForElement('span[class^="termly-styles-link"]', timeout).then(elements => {
                // elements should be a list of spans
                const [firstSpanChild] = elements;
                // the parent should be the text container
                const cookieBanner = firstSpanChild.parentElement;
                if (!cookieBanner)
                    return;
                cookieBanner.childNodes.forEach((child, childIndex) => {
                    // if it's not a text block, do nothing
                    if (child.nodeType !== Node.TEXT_NODE)
                        return;
                    // split the text block into sentences
                    const sentences = child.textContent?.split('. ');
                    if (!sentences?.length)
                        return;
                    // create a div which holds the text content for the current text block
                    const div = document.createElement('div');
                    div.style.display = 'inline';
                    if (child.previousSibling?.nodeType === Node.ELEMENT_NODE) {
                        // display a dot after the span before the current element, otherwise there would be no clear indication of a sentence finish and no space between the two
                        const span = document.createElement('span');
                        if (child.nextSibling) {
                            span.textContent = '. ';
                        }
                        else {
                            span.textContent = '.';
                        }
                        div.append(span);
                    }
                    sentences.forEach((sentence, sentenceIndex) => {
                        // for each sentence, create a paragraph
                        const paragraph = document.createElement('p');
                        // remove the dots, there are some sentences which have another character after the dot instead of whitespace
                        const dotStrippedSentence = sentence.replace(/\./gi, '');
                        if ((childIndex === 0 && sentenceIndex > 2) || childIndex > 1) {
                            paragraph.style.display = 'inline';
                            paragraph.textContent = dotStrippedSentence;
                        }
                        else {
                            // add an ending dot to the text
                            paragraph.textContent = dotStrippedSentence + '. ';
                        }
                        div.append(paragraph);
                    });
                    cookieBanner.style.width = '100%';
                    cookieBanner.replaceChild(div, child);
                });
                // after the manipulation is finished, show the cookie consent prompt
                showCookieBannerPrompt();
            });
        };
        replaceCookieBannerTextBlocks();
    }, []);
    registerFetchIntercept();
    const getLayout = Component.getLayout ?? (page => page);
    return (<>
      <GoogleTagManager gtmId='GTM-M94WMRKG'/>

      <style jsx global>
        {`
          :root {
            --poppins: ${poppins.style.fontFamily};
            --quicksand: ${quicksand.style.fontFamily};
          }

          body {
            width: 100vw;
            height: 100%;
            min-width: 100%;
            min-height: 100vh;
            margin: 0;
            position: relative;
            overflow-x: hidden;
          }

          .drawer__backdrop-closed {
            opacity: 0;
          }

          .drawer__container-left-closed {
            transform: translateX(-350px);
          }
        `}
      </style>

      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1'/>

        {!process.env.NEXT_PUBLIC_STAGE?.toLowerCase()?.includes('prod') && (<meta name='robots' content='noindex, nofollow'/>)}
      </Head>

      <main>
        <ErrorBoundary>
          <Providers>
            <AuthModalObserver />

            {/* <LoadingStatesObserver /> */}

            <Portal.Root id='portal'>
              <Overlay />

              <Drawer />
            </Portal.Root>

            <Intercom />

            {isMounted && (<Suspense fallback={null}>
                <ToastContainer containerId='toast-container__desktop' position='top-center' {...toastProps} className={`!top-10 hidden md:block ${toastProps.className}`}/>
              </Suspense>)}

            {isMounted && (<Suspense fallback={null}>
                <ToastContainer containerId='toast-container__mobile' position='bottom-center' {...toastProps} className={`!bottom-8 !left-1/2 block !-translate-x-1/2 md:hidden ${toastProps.className}`}/>
              </Suspense>)}

            <RouteGuard>
              <div id='body-wrapper' className='w-full'>
                {getLayout(<Component {...pageProps}/>)}
              </div>
            </RouteGuard>

            <ThirdPartyScriptsBody />
          </Providers>
        </ErrorBoundary>
      </main>
    </>);
}
const __Next_Translate__Page__195f695dc48__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195f695dc48__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  