import { Currencies } from 'constants/currencies'
import { useCurrencies } from 'contexts/hooks/useCurrencies'

interface Props {
  currency?: string
}

export function CurrencySymbol({ currency }: Props) {
  const { userCurrency } = useCurrencies()
  const _currency = currency || userCurrency

  switch (_currency) {
    case Currencies.USD:
      return '$'
    case Currencies.EUR:
      return '€'

    case Currencies.GBP:
    default:
      return <>&pound;</>
  }
}
