export const Currencies = {
  /**
   * The currency detected for the current user.
   */
  USER: 'USER',
  GBP: 'GBP',
  USD: 'USD',
  EUR: 'EUR',
} as const

export type Currency = keyof typeof Currencies

export type NonUserCurrency = Omit<Currency, 'USER'>
